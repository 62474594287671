@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  font-family: "Roboto", sans-serif !important;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

@import "primeflex/primeflex.scss";

.mybutton {
  @include styleclass("roboto-light");
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.rootContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.smallFont {
  font-size: 12px !important;
}

.default-top-margin {
  margin-top: 2em;
}

.default-bot-margin {
  margin-bottom: 2em;
}

.app-nav {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.table-container {
  width: 60%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myTable {
  height: 80%;
  width: 60%;
  margin-top: 2em;
}

.margin-center {
  margin: auto auto;
}

.warning {
  color: rgba(255, 165, 0, 10) !important;
}

.warning-back {
  background-color: rgba(255, 165, 0, 10) !important;
}

.danger {
  color: rgba(255, 0, 0, 10) !important;
}

.danger-back {
  background-color: rgba(255, 0, 0, 10) !important;
}

.iframe {
  width: 80% !important;
  height: 80% !important;
  display: block !important;
  margin: auto !important;
  border: 0 !important;
}

.publishList {
  overflow-y: auto;
  max-height: 70%;
  min-width: 20%;
}

.icons {
  display: flex;
  justify-content: center;
}

.base-form {
  width: 20em;
  display: flex;
  flex-direction: column;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacer {
  flex: 1 1 auto;
}

.mat-input-wrapper {
  width: 400px !important;
}

.base-table {
  height: 75% !important;
  min-width: 50% !important;
}

.table-auto-width {
  max-height: 75% !important;
  width: auto !important;
}

.base-table-vsm {
  height: 75% !important;
  width: 30% !important;
}

.base-table-sm {
  width: 100%;
  max-width: 1200px;
  min-width: 200px;
  margin-top: 60px;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.table-width {
  width: 50% !important;
}

.center-align {
  vertical-align: sub !important;
  padding-top: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

:root {
  --color1: rgb(229, 217, 182);
  --color2: rgb(177, 199, 149);
  --color3: rgb(95, 141, 78);
  --color4: rgb(40, 84, 48);

  /* Define the path without quotes */
  --background-image: url('assets/pexels-kendall-hoopes-1796794.jpg');
}

.styled-background {
  background-image: linear-gradient(
      45deg,
      rgba(250, 139, 255, 0.2) 0%,
      rgba(43, 210, 255, 0.2) 50%,
      rgba(43, 255, 136, 0.05) 100%
  ),
  var(--background-image);
  opacity: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
}

.mat-form-field {
  background-clip: padding-box !important;
}

.mat-input-wrapper {
  background-color: #fff !important;
}

.mat-form-field-flex {
  background-color: #fff !important;
}

.table-card {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden !important;
}

.base-card {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.base-card-scroll {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.base-host-center {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.base-host-start {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.tab-group {
  width: 100%;
  height: 100%;
}

.mat-toolbar {
  background: rgba(0, 0, 0, 0.87);
  color: whitesmoke;
}

.mat-drawer-container {
  position: inherit !important;
}

.mat-drawer-inner-container {
  width: 200px !important;
  background: rgba(0, 0, 0, 0.87);
}

/*.mat-list-base, .mat-list-item {
  color: whitesmoke !important;

}*/

.base-margin {
  margin: 1em !important;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}



h1 {
  font-family: "Oswald", sans-serif !important;
  font-size: 40px !important;
}

h2 {
  font-family: "Oswald", sans-serif !important;
  font-size: 32px !important;
}

p {
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.background-div ::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 100%;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );

  position: absolute;
}

.card-content-with-margins {
  height: calc(100% - 40px);
  width: calc(100% - 40px);

  overflow: hidden;
}

/*
PRIMENG
*/

/*
MATERIAL
*/

/*CALENDAR NO SPACING -------------------------------------------------------------------------------------------------*/
[viewPlayerUniqueStyle] .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

[viewPlayerUniqueStyle]
  .mat-form-field-wrapper
  > div:first-child
  > div:nth-child(3) {
  padding: 0 !important;
}

/*CALENDAR NO SPACING END --------------------------------------------------------------------------------------------*/

/*ROUNDED BUTTON WITH FONT AWESOME ICON -------------------------------------------------------------------------------*/
[fontAwesomeButtonRoundedOutline] > button {
  padding: 5.95px 10.05px 6.15px 10.05px;
}

/*ROUNDED BUTTON WITH FONT AWESOME ICON END----------------------------------------------------------------------------*/

[fontAwesomeDatePickerIconBorder] > button {
  border: 1px solid gray;
}

[resultTable] .p-inputtext {
  max-width: 3.5em;
  max-height: 1em;
  font-size: 10px;
}

* {
  box-sizing: border-box;
}
